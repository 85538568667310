package ui

import js.array.push
import js.objects.jso
import kotlinx.browser.window

internal fun emitGAEvent(category: String, action: String, label: String) {
    val window = window.asDynamic()
    val dataLayer = window.dataLayer.unsafeCast<Array<Event>?>()
        ?: arrayOf<Event>().also { window.dataLayer = it }
    dataLayer.push(jso {
        event = "GAEvent"
        eventCategory = category
        eventAction = action
        eventLabel = label
    })
}

private external interface Event {
    var event: String?
    var eventCategory: String?
    var eventAction: String?
    var eventLabel: String?
}