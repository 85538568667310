package com.intellij.generator.web.api

import com.intellij.generator.web.api.Target.Option
import com.intellij.generator.web.api.Target.Option.Variant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ProjectSpec(
    @SerialName("template_id") val templateId: TemplateId,
    @SerialName("targets") val targets: Map<Target.Id, Map<Option.Id, Set<Variant.Id>>>
)
