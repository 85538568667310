package com.intellij.generator.web.api

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.json.JsonObject
import kotlin.random.Random

@Serializable
enum class TemplateId {
    @SerialName("kmt-example") KmtExample,
    @SerialName("kmt") Kmt,
}

@Serializable
data class DefaultValue(
    @SerialName("default") val default: String
)

@Serializable
data class Target(
    @SerialName("name") val name: String,
    @SerialName("description") val description: String? = null,
    @SerialName("tags") val tags: Set<String> = emptySet(),
    @SerialName("options") val options: Map<Option.Id, Option>,
    @SerialName("enabled") val enabled: Boolean = true,
) {
    @Serializable
    enum class Id {
        @SerialName("android")
        Android,

        @Suppress("EnumEntryName")
        @SerialName("ios")
        iOS,

        @SerialName("web")
        Web,

        @SerialName("desktop")
        Desktop,

        @SerialName("server")
        Server
    }

    @Serializable
    data class Option(
        @SerialName("name") val name: String? = null,
        @SerialName("single") val single: Boolean,
        @SerialName("defaults") val defaults: List<Variant.Id>,
        @SerialName("variants") val variants: List<Variant>
    ) {
        @Serializable
        enum class Id {
            @SerialName("ui")
            UI,

            @SerialName("engine")
            Engine,
        }

        @Serializable
        data class Variant(
            @SerialName("id") val id: Id,
            @SerialName("name") val name: String,
            @SerialName("enabled") val enabled: Boolean = true,
            @SerialName("meta") val meta: JsonObject? = null,
            @SerialName("tags") val tags: Set<String> = emptySet(),
        ) {
            @Serializable
            enum class Id {
                @SerialName("xml")
                Xml,

                @SerialName("compose")
                Compose,

                @SerialName("swiftui")
                SwiftUI,

                @SerialName("ktor")
                Ktor,
            }
        }
    }
}

@Serializable
data class TemplateDescription(
    @SerialName("id") val id: String,
    @SerialName("image_url") val imageUrl: String,
    @SerialName("title") val title: String,
    @SerialName("targets") val targets: List<Target.Id>,
    @SerialName("description") val description: String,
    @SerialName("zip_url") val zipUrl: String,
    @SerialName("sources_url") val sourcesUrl: String? = null,
)

@Serializable
data class ServerConfig(
    @SerialName("project_name") val projectName: DefaultValue,
    @SerialName("project_id") val projectId: DefaultValue,
    @SerialName("documentation_url") val documentationUrl: String,
    @SerialName("new_issue_url") val newIssueUrl: String,
    @SerialName("targets") val targets: Map<Target.Id, Target>,
    @SerialName("default_target_ids") val defaultTargetIds: List<Target.Id>,
    @SerialName("templates") val templates: List<TemplateDescription> = emptyList(),
    @SerialName("templates_tags") val templatesTags: Set<String> = emptySet(),
    @Transient val salt: Int = Random.nextInt(),
)