import com.russhwolf.settings.Settings
import kotlin.reflect.KProperty

class AppPreferences(private val settings: Settings) {
    var previousProjectId by Pref("previous_project_id")

    private inner class Pref(val key: String) {
        operator fun getValue(thisRef: Any?, property: KProperty<*>): String? {
            return settings.getStringOrNull(key)
        }

        operator fun setValue(thisRef: Any?, property: KProperty<*>, value: String?) {
            if (value == null) settings.remove(key)
            else settings.putString(key, value)
        }
    }
}