package ui

import mui.icons.material.Brightness4
import mui.icons.material.Brightness7
import mui.icons.material.MenuBook
import mui.material.Box
import mui.material.IconButton
import mui.material.IconButtonColor
import react.FC
import react.Props
import react.useRequiredContext
import web.window.window

val TopMenu = FC<Props> {
    var theme by useRequiredContext(ThemeContext)
    val serverConfig = useRequiredContext(ServerConfigContext)
    Box {
        IconButton {
            id = "viewDocumentation"
            onClick = {
                window.open(serverConfig.documentationUrl)
            }
            color = IconButtonColor.primary
            title = "View Kotlin Multiplatform documentation"
            MenuBook()
        }

        IconButton {
            id = "toggleThemeDarkLight"
            onClick = {
                theme = if (theme == Themes.Light) Themes.Dark else Themes.Light
            }
            color = IconButtonColor.primary
            if (theme == Themes.Light) {
                title = "Use dark theme"
                Brightness7()
            } else {
                title = "Use light theme"
                Brightness4()
            }
        }
    }
}
