package ui

import AppPreferences
import com.intellij.generator.web.api.ServerConfig
import kotlinx.browser.window
import mui.material.CssBaseline
import mui.material.styles.Theme
import mui.material.styles.ThemeProvider
import react.*

val ThemeContext = createContext<StateInstance<Theme>>()
val ServerConfigContext = createContext<ServerConfig>()
val AppPreferencesContext = createContext<AppPreferences>()
val VariantContext = createContext<AppVariant>()

interface AppVariant {
    val url: String
    val address: String?
    fun addLinks(addLink: (href: String, title: String) -> Unit)
}

external interface AppProps : Props {
    var config: ServerConfig
    var preferences: AppPreferences
    var variant: AppVariant
}

val App = FC<AppProps> { props ->
    ThemeContext.Provider {
        val currentThemeIsDark = window.matchMedia("(prefers-color-scheme: dark)").matches
        val state = useState(
            if (currentThemeIsDark) Themes.Dark else Themes.Light
        )
        value = state

        ThemeProvider {
            theme = state.component1()
            CssBaseline()

            ServerConfigContext.Provider {
                value = props.config
                AppPreferencesContext.Provider {
                    value = props.preferences
                    VariantContext.Provider {
                        value = props.variant
                        Content()
                    }
                }
            }
        }
    }
}
