package ui

import mui.icons.material.SvgIconComponent
import mui.material.SvgIcon
import react.FC
import web.cssom.Globals.Companion.inherit

private fun createSvgIcon(svgFile: SvgFile): SvgIconComponent = FC { props ->
    SvgIcon {
        +props
        component = svgFile.component
        inheritViewBox = true
    }
}

@JsModule("./icons/jb-kotlin-logo.svg")
@JsNonModule
external val JBKotlinLogoFile: SvgFile
val JBKotlinLogo: SvgIconComponent = FC { props ->
    createSvgIcon(JBKotlinLogoFile).invoke {
        +props
        sx.asDynamic().fill = inherit // don't use current color
    }
}

@JsModule("./icons/android.svg")
@JsNonModule
external val AndroidLogoFile: SvgFile
val AndroidLogo = createSvgIcon(AndroidLogoFile)

@JsModule("./icons/ios.svg")
@JsNonModule
external val IosLogoFile: SvgFile
val IosLogo = createSvgIcon(IosLogoFile)

@JsModule("./icons/desktop.svg")
@JsNonModule
external val DesktopLogoFile: SvgFile
val DesktopLogo = createSvgIcon(DesktopLogoFile)

@JsModule("./icons/web.svg")
@JsNonModule
external val WebLogoFile: SvgFile
val WebLogo = createSvgIcon(WebLogoFile)

@JsModule("./icons/server.svg")
@JsNonModule
external val ServerLogoFile: SvgFile
val ServerLogo = createSvgIcon(ServerLogoFile)
