package ui

import com.intellij.generator.web.api.Target
import com.intellij.generator.web.api.Target.Option
import com.intellij.generator.web.api.Target.Option.Variant
import mui.icons.material.CheckBox
import mui.icons.material.CheckBoxOutlineBlank
import mui.icons.material.SvgIconComponent
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.PropsWithSx
import mui.system.responsive
import mui.system.sx
import react.*
import web.cssom.AlignItems
import web.cssom.JustifyContent
import web.cssom.Width
import web.cssom.px

external interface TargetCardProps : PropsWithChildren {
    var width: Width?
    var icon: SvgIconComponent?
    var target: Target
    var selected: StateInstance<Boolean>
    var selectedOptions: Map<Option.Id, StateInstance<Set<Variant.Id>>>
}

val TargetCard = FC<TargetCardProps> { props ->
    val theme by useRequiredContext(ThemeContext)
    val isSelected by props.selected

    Card {
        id = "target" + props.target.name
        variant = PaperVariant.outlined
        sx {
            width = props.width
            if (!props.target.enabled) backgroundColor = theme.palette.action.disabledBackground
        }
        Stack {
            if (props.target.enabled) {
                CardActionArea {
                    mainCard(props)
                }
            }
            else {
                mainCard(props)
            }

            props.target.description?.let {
                Box {
                    sx {
                        paddingBottom = 16.px
                        paddingLeft = 16.px
                        paddingRight = 16.px
                    }
                    Typography {
                        variant = TypographyVariant.body2
                        +parse(it, SafeParserOptions)
                    }
                }
            }

            if (isSelected) {
                Box {
                    sx {
                        paddingBottom = 16.px
                        paddingLeft = 16.px
                        paddingRight = 16.px
                    }
                    Stack {
                        for ((id, option) in props.target.options) {
                            TargetOptionsComponent {
                                target = props.target
                                targetOptionId = id
                                targetOption = option
                                selectedVariants = props.selectedOptions[id]!!
                            }
                        }
                    }
                }

                +props.children
            }
        }
    }
}

private fun <T> T.mainCard(props: TargetCardProps) where T : ChildrenBuilder, T : PropsWithSx {
    val theme by useRequiredContext(ThemeContext)
    var isSelected by props.selected
    Stack {
        direction = responsive(StackDirection.row)
        sx {
            alignItems = AlignItems.center
            justifyContent = JustifyContent.spaceBetween
            padding = 16.px
            if (!props.target.enabled) color = theme.palette.action.disabled
        }
        if (props.target.enabled) {
            id = (if (isSelected) "disable" else "enable") + "Target" + props.target.name
            onClick = {
                isSelected = !isSelected
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                alignItems = AlignItems.center
            }

            props.icon?.invoke {
                sx {
                    fontSize = 40.px
                    marginRight = 16.px
                }
            }
            Typography {
                sx {
                    marginRight = 16.px
                }
                variant = TypographyVariant.h5
                +props.target.name
            }
            props.target.tags.forEach { tag ->
                Chip {
                    sx {
                        marginLeft = (-4).px
                        marginRight = 16.px
                        if (!props.target.enabled) color = theme.palette.action.disabled
                    }
                    label = ReactNode(tag)
                    size = Size.small
                    variant = ChipVariant.outlined
                }
            }
        }
        if (isSelected) {
            CheckBox()
        } else {
            CheckBoxOutlineBlank()
        }
    }
}