package ui

import mui.icons.material.GitHub
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.PropsWithSx
import mui.system.responsive
import mui.system.sx
import react.FC
import react.ReactNode
import react.create
import react.useRequiredContext
import web.cssom.JustifyContent
import web.cssom.px
import web.cssom.unaryMinus

val TemplateLibrary = FC<PropsWithSx> {
    val serverConfig = useRequiredContext(ServerConfigContext)
    Stack {
        sx = it.sx
        direction = responsive(StackDirection.column)
        spacing = responsive(2)

        serverConfig.templates.forEach { template ->
            Card {
                id = "template" + template.id

                variant = PaperVariant.outlined
                CardMedia {
                    sx {
                        height = 200.px
                    }
                    image = template.imageUrl
                }
                CardContent {
                    Typography {
                        variant = TypographyVariant.h5
                        +template.title
                    }
                    Stack {
                        sx {
                            marginTop = 8.px
                            marginBottom = 8.px
                        }
                        direction = responsive(StackDirection.row)
                        spacing = responsive(1)
                        template.targets.forEach { target ->
                            Chip {
                                label = ReactNode(target.name)
                                size = Size.small
                                variant = ChipVariant.outlined
                            }
                        }
                    }
                    Typography {
                        variant = TypographyVariant.body1
                        asDynamic().color = "text.secondary"
                        +template.description
                    }
                }
                CardActions {
                    sx {
                        justifyContent = JustifyContent.flexEnd
                        marginTop = -8.px
                        marginRight = 4.px
                    }
                    template.sourcesUrl?.let { sourcesUrl ->
                        Button {
                            id = "browseTemplate" + template.id
                            size = Size.small
                            startIcon = GitHub.create()
                            href = sourcesUrl
                            onClick = {
                                runCatching { emitGAEvent("Kotlin Multiplatform", "TemplateGallery - Browse Sources", template.id) }
                                    .onFailure { console.error(it) }
                            }
                            +"Sources"
                        }
                    }
                    Button {
                        id = "downloadTemplate" + template.id
                        size = Size.small
                        href = template.zipUrl
                        onClick = {
                            runCatching { emitGAEvent("Kotlin Multiplatform", "TemplateGallery - Download", template.id) }
                                .onFailure { console.error(it) }
                        }
                        +"Download"
                    }
                }
            }
        }
    }
}