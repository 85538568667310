import com.intellij.generator.web.api.ServerConfig
import com.intellij.generator.web.api.request.ServerConfigRequest
import com.russhwolf.settings.StorageSettings
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.engine.js.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import react.create
import react.dom.client.createRoot
import ui.App
import ui.AppProps
import web.dom.document
import web.html.HTML.div
import web.location.location

internal fun URLBuilder.setupRequest(): URLBuilder = this.apply {
    //port = 8080
    val basePath = location.pathname.split("/").filter { it.isNotEmpty() }.map { it.encodeURLPathPart() }
    encodedPathSegments = basePath + (encodedPathSegments.takeIf { it.isNotEmpty() } ?: listOf(""))
}

suspend fun launchApp(configure: AppProps.() -> Unit) {
    val client = HttpClient(Js) {
        install(Resources)
        install(ContentNegotiation) {
            json()
        }
        defaultRequest {
            url.setupRequest()
        }
    }


    val serverConfig = client.get(ServerConfigRequest).body<ServerConfig>()
    val appPreferences = AppPreferences(StorageSettings())

    println("SERVER CONFIG: $serverConfig")
    val app = App.create {
        config = serverConfig
        preferences = appPreferences
        configure()
    }
    val root = document.createElement(div).also { document.body.appendChild(it) }
    createRoot(root).render(app)
}
