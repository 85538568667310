package ui

import mui.icons.material.BugReport
import mui.material.*
import mui.material.Size
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.useRequiredContext
import web.cssom.*

val Footer = FC<Props> {
    val theme by useRequiredContext(ThemeContext)

    Stack {
        direction = responsive(StackDirection.column)
        spacing = responsive(12.px)

        val appVariant = useRequiredContext(VariantContext)
        Stack {
            direction = responsive(StackDirection.row)
            sx {
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.baseline
            }

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(1)

                fun addLink(href: String, title: String) {
                    Typography {
                        variant = TypographyVariant.body2
                        Link {
                            this.href = href
                            +title
                        }
                    }
                }
                appVariant.addLinks(::addLink)
                addLink("${appVariant.url}/privacy-security/", "Privacy & Security")
                addLink("${appVariant.url}/legal/docs/company/useterms.html", "Terms of Use")
                addLink("${appVariant.url}/legal/trademarks/", "Trademarks")
                addLink("${appVariant.url}/legal/", "Legal")
                addLink("${appVariant.url}/genuine-tools/", "Genuine Tools")
            }

            val serverConfig = useRequiredContext(ServerConfigContext)
            Button {
                id = "reportIssue"
                +"Report an issue"
                size = Size.small
                startIcon = BugReport.create()
                href = serverConfig.newIssueUrl
            }
        }

        appVariant.address?.let {
            Typography {
                variant = TypographyVariant.body2
                sx {
                    color = theme.palette.action.disabled
                }
                +it
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                justifyContent = JustifyContent.spaceBetween
            }

            Typography {
                variant = TypographyVariant.body2
                +"Copyright © 2023-2024 "
                Link {
                    sx {
                        textDecoration = None.none
                        hover {
                            textDecoration = TextDecoration.underline
                        }
                    }
                    href = "${appVariant.url}/"
                    +"JetBrains s.r.o."
                }
            }

            Typography {
                variant = TypographyVariant.body2
                +"Developed with drive and "
                Link {
                    href = "${appVariant.url}/fleet/"
                    +"Fleet"
                }
            }
        }
    }
}