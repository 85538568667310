package ui

import com.intellij.generator.validator.ProjectValidator
import com.intellij.generator.web.api.ComposeResources
import com.intellij.generator.web.api.ProjectSpec
import com.intellij.generator.web.api.Target
import com.intellij.generator.web.api.Target.Option
import com.intellij.generator.web.api.Target.Option.Variant
import com.intellij.generator.web.api.TemplateId
import com.intellij.generator.web.api.request.GenerateProjectRequest
import io.ktor.http.*
import io.ktor.resources.*
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import mui.icons.material.SvgIconComponent
import mui.material.*
import mui.system.PropsWithSx
import mui.system.responsive
import mui.system.sx
import react.*
import react.dom.html.ReactHTML
import react.dom.onChange
import web.cssom.None
import web.html.ButtonType
import web.html.HTMLInputElement
import web.html.InputType

internal external interface NewProjectFormProps : PropsWithSx {
    var stateHolder: NewProjectStateHolder
}

internal class NewProjectStateHolder(val uid: StateInstance<String?>) {
    val projectName: StateInstance<String>
    val projectNameError: StateInstance<Throwable?>
    val projectId: StateInstance<String>
    val projectIdError: StateInstance<Throwable?>
    val selected: Map<Target.Id, StateInstance<Boolean>>
    val selectedOptions: Map<Target.Id, Map<Option.Id, StateInstance<Set<Variant.Id>>>>

    init {
        val serverConfig = useRequiredContext(ServerConfigContext)
        projectName = useState(serverConfig.projectName.default)
        projectNameError = useState(null)

        val defaultProjectId = useRequiredContext(AppPreferencesContext).previousProjectId ?: serverConfig.projectId.default
        projectId = useState(defaultProjectId)
        projectIdError = useState(null)

        selected = serverConfig.targets.mapValues { (targetId, _) ->
            useState(targetId in serverConfig.defaultTargetIds)
        }
        selectedOptions = serverConfig.targets.mapValues { (_, target) ->
            target.options.mapValues { (_, option) -> useState(option.defaults.toSet()) }
        }
    }
}

@OptIn(ExperimentalSerializationApi::class)
internal val NewProjectForm = FC<NewProjectFormProps> { props ->
    val serverConfig = useRequiredContext(ServerConfigContext)
    val prefs = useRequiredContext(AppPreferencesContext)

    ReactHTML.form {
        method = HttpMethod.Get.value
        action = GenerateProjectRequest.serializer().descriptor.annotations.filterIsInstance<Resource>().single().path

        Stack {
            sx = props.sx
            direction = responsive(StackDirection.column)
            spacing = responsive(2)

            var projectName by props.stateHolder.projectName
            var projectNameError by props.stateHolder.projectNameError
            TextField {
                id = "projectName"
                name = "name"
                label = ReactNode("Project Name")
                error = projectNameError != null
                helperText = ReactNode(projectNameError?.message ?: "")
                value = projectName
                autoComplete = "off"
                onChange = { event ->
                    val input = (event.target as HTMLInputElement).value
                    projectNameError = runCatching {
                        ProjectValidator.validateProjectName(input.trim())
                        ProjectValidator.validatePackageName(ComposeResources.derivePackageName(input.replace(Regex("\\s"), "")), "Compose Resources package")
                    }.exceptionOrNull()
                    projectName = input
                }
                onBlur = {
                    projectName = projectName.trim()
                }
            }

            var projectId by props.stateHolder.projectId
            var projectIdError by props.stateHolder.projectIdError

            TextField {
                id = "projectId"
                name = "id"
                label = ReactNode("Project ID")
                error = projectIdError != null
                helperText = ReactNode(projectIdError?.message ?: "")
                value = projectId
                autoComplete = "off"
                onChange = { event ->
                    val input = (event.target as HTMLInputElement).value
                    projectIdError = runCatching { ProjectValidator.validatePackageName(input.trim()) }.exceptionOrNull()
                    projectId = input
                }
                onBlur = {
                    projectId = projectId.trim()
                }
            }

            serverConfig.targets.forEach { (targetId, target) ->
                createTargetCard(targetId, target, props.stateHolder)
            }

            Fragment {
                val uid by props.stateHolder.uid
                uid?.let {
                    ReactHTML.input {
                        type = InputType.hidden
                        name = "uid"
                        value = it
                    }
                }
            }

            Fragment {
                val spec = ProjectSpec(
                    templateId = TemplateId.Kmt,
                    targets = props.stateHolder.selected.entries.asSequence()
                        .mapNotNull { (targetId, selectedState) ->
                            targetId.takeIf { selectedState.let { (isSelected, _) -> isSelected } }
                        }
                        .associateWith { targetId ->
                            props.stateHolder.selectedOptions[targetId]
                                ?.mapValues { (_, state) -> state.let { (variants, _) -> variants } }
                                ?: emptyMap<Option.Id, Set<Variant.Id>>().also {
                                    reportMissingTargetInState(targetId, props.stateHolder)
                                }
                        }
                )

                ReactHTML.input {
                    type = InputType.hidden
                    name = "spec"
                    value = Json.encodeToString(spec)
                }

                Button {
                    id = "downloadNewProject"
                    type = ButtonType.submit

                    val configIsValid =
                        projectNameError == null &&
                                projectIdError == null &&
                                props.stateHolder.selected.values.any { (isSelected, _) ->
                                    isSelected
                                }

                    disabled = !configIsValid
                    variant = ButtonVariant.contained
                    color = ButtonColor.secondary
                    sx {
                        boxShadow = None.none
                    }
                    size = Size.large
                    +"Download"

                    onClick = {
                        runCatching {
                            val label =
                                (listOf("Template" to spec.templateId) + spec.targets.flatMap { (target, options) ->
                                    if (options.isEmpty()) return@flatMap emptyList()
                                    listOf(target to "y") + options.flatMap { (option, variants) ->
                                        variants.sorted().map { "$target$option" to it }
                                    }
                                }).joinToString(" ") { (k, v) -> "$k=$v" }
                            emitGAEvent("Kotlin Multiplatform", "NewProject - Download", label)
                        }.onFailure { console.error(it) }

                        prefs.previousProjectId = projectId
                    }
                }
            }
        }
    }
}

private fun targetLogo(targetId: Target.Id): SvgIconComponent = when (targetId) {
    Target.Id.Android -> AndroidLogo
    Target.Id.iOS -> IosLogo
    Target.Id.Desktop -> DesktopLogo
    Target.Id.Web -> WebLogo
    Target.Id.Server -> ServerLogo
}

private fun ChildrenBuilder.createTargetCard(
    targetId: Target.Id, target: Target, stateHolder: NewProjectStateHolder
) {
    val selected = stateHolder.selected[targetId]
    val selectedOptions = stateHolder.selectedOptions[targetId]
    if (selected == null || selectedOptions == null) return reportMissingTargetInState(targetId, stateHolder)
    TargetCard {
        this.icon = targetLogo(targetId)
        this.target = target
        this.selected = selected
        this.selectedOptions = selectedOptions
    }
}

private fun reportMissingTargetInState(targetId: Target.Id, stateHolder: NewProjectStateHolder) {
    console.error(
        "Missing target $targetId in state.\n" +
                "selected:%o\n" +
                "selectedOptions:%o\n",
        stateHolder.selected, stateHolder.selectedOptions
    )
}
