package com.intellij.generator.validator

import com.intellij.generator.validator.ValidationError.*
import com.intellij.generator.web.api.ComposeResources

object ProjectValidator {
    private val projectNameRegex = Regex("""^[a-zA-Z][a-zA-Z0-9\s_-]*$""")
    private val projectNameFirstCharRegex = Regex("""^[a-zA-Z].*$""")
    private val prohibitedProjectNames = listOf(
        "project",
        "iosApp",
        "androidApp",
        "composeApp",
        "server",
        "shared",
    )

    fun validateProjectName(input: String) {
        if (input.isBlank()) EmptyProjectName.error()

        if (!input.matches(projectNameRegex)) when {
            !input.matches(projectNameFirstCharRegex) -> InvalidProjectNameStart.error()
            else -> InvalidProjectNameContent.error()
        }

        val inputNoSpaces = input.replace(Regex("\\s"), "")
        val isProhibited = prohibitedProjectNames.any { it.equals(inputNoSpaces, ignoreCase = true) }
        if (isProhibited) ProhibitedProjectName.error(input)

        ComposeResources.derivePackageName(inputNoSpaces).also {
            validatePackageName(it, "Compose Resources package")
        }
    }

    private val kotlinPackageRegex = Regex("""^[a-z][a-z0-9_]*(\.[a-z][a-z0-9_]*)*$""")
    private val kotlinPackageFirstCharRegex = Regex("""^[a-z].*$""")
    private val kotlinPackageLastCharRegex = Regex("""^.*[a-z0-9_]$""")
    private val kotlinPackagePartRegex = Regex("""^[a-z][a-z0-9_]*$""")

    fun validatePackageName(input: String, contextName: String = "project ID") {
        if (input.isBlank()) EmptyProjectId.error(contextName)

        if (!input.matches(kotlinPackageFirstCharRegex)) InvalidProjectIdStart.error(contextName)
        if (!input.matches(kotlinPackageLastCharRegex)) InvalidProjectIdEnd.error(contextName)

        if (!input.any { it == '.' }) InvalidProjectIdDotMissing.error(contextName)
        val packageParts = input.split(".")

        if (packageParts.first().equals("kotlin", true)) InvalidProjectIdKotlinPackage.error(contextName)
        if (packageParts.any { !it.matches(kotlinPackagePartRegex) }) InvalidProjectIdPart.error(contextName)
        if (!kotlinPackageRegex.matches(input)) InvalidProjectIdCharacter.error(contextName)

        packageParts.firstOrNull { it in JAVA_KEYWORDS }?.let { InvalidProjectIdJavaKeyword.error(contextName, it) }
    }

    fun escapeKotlinKeywordsInPackageName(input: String): String =
        input.split(".").joinToString(".") { if (it in KOTLIN_KEYWORDS) "`$it`" else it }
}

enum class ValidationError(private val messageFormat: String) {
    EmptyProjectName("Project name must not be empty"),
    InvalidProjectNameStart("Project name must start with Latin character"),
    InvalidProjectNameContent("Only Latin characters, digits, spaces, '_' and '-' are allowed in project name"),
    ProhibitedProjectName("Project name '{0}' is prohibited"),

    EmptyProjectId("{0} must not be empty"),
    InvalidProjectIdStart("{0} must start with lowercase Latin character"),
    InvalidProjectIdEnd("{0} must end with lowercase Latin character, digit or '_'"),
    InvalidProjectIdDotMissing("{0} must contain at least one '.' separator"),
    InvalidProjectIdPart("Each package part of {0} must start with lowercase Latin character and must contain only lowercase Latin character, digit or '_'"),
    InvalidProjectIdCharacter("Only lowercase Latin characters, digits, '_' and '.' are allowed in {0}"),
    InvalidProjectIdJavaKeyword("'{1}' cannot be part of {0} as it is Java keyword"),
    InvalidProjectIdKotlinPackage("{0} cannot start with 'kotlin' package - only the Kotlin standard library is allowed to use it"),
    ;

    internal fun formattedErrorMessage(vararg args: Any) = messageFormat.format(*args)

    fun error(vararg args: Any): Nothing = throw ValidationException(formattedErrorMessage(*args))
}

class ValidationException(message: String) : IllegalStateException(message)

private fun String.format(vararg args: Any): String {
    var i = 0
    return replace(Regex("\\{(\\d+)\\}")) { matchResult ->
        val renderedArgument = (matchResult.groupValues[1].toIntOrNull()?.let { args[it] } ?: args[i]).toString()
        i++
        when (matchResult.range.first) {
            0 -> renderedArgument.replaceFirstChar { it.uppercaseChar() }
            else -> renderedArgument
        }
    }
}

// https://docs.oracle.com/javase/tutorial/java/nutsandbolts/_keywords.html
private val JAVA_KEYWORDS: Set<String> = setOf(
    "abstract",
    "continue",
    "for",
    "new",
    "switch",
    "assert",
    "default",
    "goto",
    "package",
    "synchronized",
    "boolean",
    "do",
    "if",
    "private",
    "this",
    "break",
    "double",
    "implements",
    "protected",
    "throw",
    "byte",
    "else",
    "import",
    "public",
    "throws",
    "case",
    "enum",
    "instanceof",
    "return",
    "transient",
    "catch",
    "extends",
    "int",
    "short",
    "try",
    "char",
    "final",
    "interface",
    "static",
    "void",
    "class",
    "finally",
    "long",
    "strictfp",
    "volatile",
    "const",
    "float",
    "native",
    "super",
    "while",
)

// https://github.com/JetBrains/kotlin/blob/master/core/compiler.common/src/org/jetbrains/kotlin/renderer/KeywordStringsGenerated.java
private val KOTLIN_KEYWORDS: Set<String> = setOf(
    "package",
    "as",
    "typealias",
    "class",
    "this",
    "super",
    "val",
    "var",
    "fun",
    "for",
    "null",
    "true",
    "false",
    "is",
    "in",
    "throw",
    "return",
    "break",
    "continue",
    "object",
    "if",
    "try",
    "else",
    "while",
    "do",
    "when",
    "interface",
    "typeof",
)
