package ui

import js.objects.jso
import mui.material.PaletteMode.Companion.dark
import mui.material.PaletteMode.Companion.light
import mui.material.styles.ThemeOptions
import mui.material.styles.createTheme

object Themes {
    val Light = createTheme(
        jso {
            palette = jso {
                mode = light
                primary = jso {
                    main = "rgba(0, 0, 0, 0.87)"
                }
                secondary = jso {
                    main = "#7f52ff"
                }
            }
            configureThemeOptions()
        }
    )

    val Dark = createTheme(
        jso {
            palette = jso {
                mode = dark
                primary = jso {
                    main = "#fff"
                }
                secondary = jso {
                    main = "#7f52ff"
                }
            }
            configureThemeOptions()
        }
    )

    private fun ThemeOptions.configureThemeOptions() {
        typography = jso {
            fontFamily = "\"JetBrains Sans\",Inter,system-ui,-apple-system,BlinkMacSystemFont,\"Segoe UI\"," +
                    "Roboto,Oxygen,Ubuntu,Cantarell,\"Droid Sans\",\"Helvetica Neue\",Arial,sans-serif;"
            fontFeatureSettings = "\"kern\",\"liga\",\"calt\""
            lineHeight = 1.4
            fontWeight = 400
        }

        components = jso {
            MuiButtonBase = jso {
                defaultProps = jso {
                    disableRipple = true
                }
            }
        }
    }
}