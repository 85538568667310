package ui

import mui.material.Stack
import mui.material.StackDirection
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import web.cssom.AlignItems
import web.cssom.px

val Header = FC<Props> {
    Stack {
        direction = responsive(StackDirection.row)
        spacing = responsive(2)
        sx {
            alignItems = AlignItems.center
            paddingTop = 24.px
            paddingBottom = 24.px
            minWidth = 580.px
        }
        JBKotlinLogo {
            sx {
                width = 59.px
                height = 29.5.px
            }
        }
        Typography {
            variant = TypographyVariant.h3
            sx {
                fontSize = 38.px
            }
            +"Kotlin Multiplatform Wizard"
        }
    }
}