package com.intellij.generator.web.api

object ComposeResources {
    fun derivePackageName(projectName: String): String {
        val projectNameNoSpaces = projectName.replace(Regex("\\s"), "")
        val composeResPrefix = projectNameNoSpaces.toLowerCase().asComposeUnderscoredIdentifier()
        return "$composeResPrefix.composeapp.generated.resources"
    }

    // taken from https://github.com/JetBrains/compose-multiplatform/blob/master/gradle-plugins/compose/src/main/kotlin/org/jetbrains/compose/resources/GenerateResClassTask.kt
    private fun String.asComposeUnderscoredIdentifier(): String =
        replace('-', '_')
            .let { if (it.isNotEmpty() && it.first().isDigit()) "_$it" else it }
}