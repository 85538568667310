package ui

import mui.material.*
import mui.material.Size
import mui.system.Container
import mui.system.responsive
import mui.system.sx
import react.*
import web.cssom.*
import web.events.EventType
import web.events.addEventListener
import web.location.location
import web.window.window

val Content = FC<Props> {
    val theme by useRequiredContext(ThemeContext)
    val uidState = useState<String?>(null)

    Container {
        sx {
            padding = Padding(24.px, 24.px)
            minWidth = 650.px
        }

        Stack {
            direction = responsive(StackDirection.column)
            spacing = responsive(1)

            val serverConfig = useRequiredContext(ServerConfigContext)

            Paper {
                sx {
                    padding = Padding(24.px, 24.px)
                }
                variant = PaperVariant.outlined

                Stack {
                    direction = responsive(StackDirection.column)
                    spacing = responsive(2)
                    sx {
                        alignItems = AlignItems.center
                    }

                    Header()

                    val hasTemplates = serverConfig.templates.isNotEmpty()

                    val newProject = "newProject"
                    val templateLibrary = "templateGallery"
                    var activeTab by useState(location.hash.substring(1).takeIf { hasTemplates && it == templateLibrary } ?: newProject)

                    useEffectOnce {
                        window.addEventListener(EventType("hashchange"), {
                            when (val tab = location.hash.substring(1)) {
                                newProject -> activeTab = tab
                                templateLibrary -> if (hasTemplates) activeTab = tab
                            }
                        })
                    }

                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            justifyContent = JustifyContent.spaceBetween
                            padding = Padding(4.px, 0.px)
                            width = 580.px
                        }

                        if (hasTemplates || serverConfig.templatesTags.isNotEmpty()) {
                            Tabs {
                                value = activeTab
                                onChange = { _, newTab ->
                                    location.hash = "#${newTab}"
                                    activeTab = newTab as String
                                }
                                centered = false
                                Tab {
                                    id = "tabNewProject"
                                    value = newProject
                                    label = ReactNode("New Project")
                                    sx {
                                        textTransform = None.none
                                    }
                                }
                                Tab {
                                    id = "tabTemplates"
                                    value = templateLibrary
                                    disabled = !hasTemplates
                                    label = Stack.create {
                                        direction = responsive(StackDirection.row)
                                        sx {
                                            alignItems = AlignItems.center
                                            textTransform = None.none
                                        }

                                        +"Template Gallery"

                                        serverConfig.templatesTags.forEach { tag ->
                                            Chip {
                                                sx {
                                                    marginLeft = 8.px
                                                    if (!hasTemplates) color = theme.palette.action.disabled
                                                }
                                                label = ReactNode(tag)
                                                size = Size.small
                                                variant = ChipVariant.outlined
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        TopMenu()
                    }

                    val newProjectStateHolder = NewProjectStateHolder(uidState)
                    if (activeTab == templateLibrary) {
                        TemplateLibrary {
                            sx {
                                width = 580.px
                            }
                        }
                    } else if (activeTab == newProject) {
                        NewProjectForm {
                            sx {
                                width = 580.px
                            }
                            stateHolder = newProjectStateHolder
                        }
                    }
                }
            }

            Footer()
        }
    }
}
