package ui

import mui.material.Link
import mui.material.LinkProps
import react.*
import ui.HtmlReactParser.HTMLReactParserOptions

@JsNonModule
@JsModule("html-react-parser")
external fun parse(html: String, options: HTMLReactParserOptions = definedExternally): ReactNode

@JsNonModule
@JsModule("html-react-parser")
external object HtmlReactParser {
    fun domToReact(children: dynamic, options: HTMLReactParserOptions = definedExternally): Array<ReactNode>

    fun attributesToProps(attribs: dynamic): Props

    interface HTMLReactParserOptions {
        val replace: (domNode: dynamic) -> ReactNode?
    }
}

internal object SafeParserOptions : HTMLReactParserOptions {
    override val replace: (domNode: dynamic) -> ReactNode? = { domNode ->
        when (domNode.type) {
            "script", "style" -> createElement(Fragment)

            "tag" -> when (domNode.name) {
                "a" -> createElement(
                    Link,
                    HtmlReactParser.attributesToProps(domNode.attribs).unsafeCast<LinkProps>(),
                    *HtmlReactParser.domToReact(domNode.children, this)
                )

                else -> null
            }

            else -> null
        }
    }
}