package com.intellij.generator.web.api.request

import com.intellij.generator.web.api.TemplateId
import io.ktor.resources.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed interface ProjectConfigProvider {
    val projectName: String
    val projectId: String
}

@Resource("generateProject")
class LegacyGenerateProjectRequest(
    val templateId: TemplateId,
    override val projectName: String,
    override val projectId: String,
    val pluginIds: List<String>,
    val tags: List<String> = emptyList()
) : ProjectConfigProvider

@Resource("generateKmtProject")
data class GenerateProjectRequest(
    @SerialName("config") val projectConfig: ProjectConfig,
    @SerialName("uid") val analyticsIdentifier: String? = null,
    @SerialName("spec") val projectSpecJson: String
)

@Serializable
data class ProjectConfig(
    @SerialName("name") override val projectName: String,
    @SerialName("id") override val projectId: String,
) : ProjectConfigProvider
